import { callLoginApi, callApi } from '../service/Api';
import { startSetUserInfo, startSetUserPref } from './user';
import { startSetShareList, startSetShareNetworks } from './share';
import { ApiConstants } from '../constants/api';
// import { history } from '../router/AppRouter';

export const login = ({ status, token, error = "" } = {}) => ({
  type: 'LOGIN',
  status,
  token,
  error
});

export const loginError = ({ status, error } = {}) => ({
    type: 'LOGIN_ERROR',
    status,
    error
});

const callLoginProcess = ({dispatch, redirect = "/all"}) => {
    dispatch(startSetUserInfo());
    dispatch(startSetUserPref());
    dispatch(startSetShareList());
    dispatch(startSetShareNetworks());
    // history.push(redirect)
}

export const startLogin = ({email:username, password, redirect = "/all"} = {}) => {
    return (dispatch, getState) => {
        callLoginApi(username, password)
        .then(data => {
            console.log('data', data)
            if(!!data.status) {
                dispatch(login({
                    status: true,
                    token: data.access_token
                }));
            }
            else {
                dispatch(loginError({
                    status: false,
                    error: data.msg
                }));
                throw new Error('Login Failed');
            }
        })
        .then(() => {
            callLoginProcess({dispatch, redirect})
        })
        .catch(error => {
            console.error('Error:', error);
        });
        
    };
};

export const logout = () => ({
  type: 'LOGOUT'
});

export const startLogout = () => {
    return (dispatch, getState) => {
        const apiData = {
            url: ApiConstants.AUTH_LOGOUT,
            token: getState().auth.token
        }
        callApi(apiData)
        .then(data => {
            if(data.status) {
                dispatch(logout());
            }
            else {
                throw new Error('Something went wrong');
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };
};

export const loginByCookie = ({ status, token, cookie, error = "" } = {}) => ({
    type: 'LOGIN_COOKIE',
    status,
    token,
    cookie,
    error
});

export const startLoginByCookie = ({cookie = "", redirect = "/all"} = {}) => {
    return (dispatch, getState) => {
        
        if(!!cookie) {

            const apiData = {
                url: ApiConstants.AUTH_COOKIE,
                data: {
                    reference: encodeURIComponent(cookie)
                }
            }

            callApi(apiData)
            .then(data => {
                if(data.status) {
                    dispatch(loginByCookie({
                        status: true,
                        token: data.access_token,
                        cookie: true
                    }));
                }
                else {
                    throw new Error('Invalid Cookie');
                }
            })
            .then(() => {
                callLoginProcess({dispatch, redirect})
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
        
    };
};

export const clearLoginError = (error = "") => ({
    type: 'CLEAR_LOGIN_ERROR',
    error
});