export const actionUpdateEntries = (updates = {}) => (dispatch, getState) => new Promise((resolve, reject) => {
    dispatch({
        type: 'ACTION_UPDATE_ENTRIES',
        updates
    });
    resolve();
});

export const actionRefreshEntries = () => (dispatch, getState) => new Promise((resolve, reject) => {
    dispatch(actionUpdateEntries({
        action:"REFRESH",
        timestamp: new Date().getTime()
    }));
    resolve();
});