const userReducerDefaultState = {
    info: {},
    pref: []
};

export default (state = userReducerDefaultState, action) => {
    // console.log("USER",action.type)
    switch (action.type) {
        case 'LOGOUT':
            return {
                ...userReducerDefaultState
            };
        case 'SET_USER_INFO':
            return {
                ...state,
                info: action.info,
            };
        case 'SET_USER_PREF':
            return {
                ...state,
                pref: action.pref,
            };
        case 'UPDATE_USER_PREF':
            return {
                ...state,
                pref: action.pref,
            };
        default:
            return state;
    }
};