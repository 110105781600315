import React from "react";
import ReactDOM from 'react-dom';
// import { Button } from '@material-ui/core';
import { Provider } from 'react-redux';
import AppRouter from "./router/AppRouter";
import configureStore from './stores/configureStore';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import '../sass/app.scss';

Sentry.init({
  dsn: process.env.SENTRY_PROJECT,
  environment: process.env.MODE=="development"?"development":"production",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

ReactDOM.render(jsx, document.getElementById('root'));