import React from "react";
import { connect } from 'react-redux';
// import {Container, Row, Col, Alert} from 'react-bootstrap';
import { startLogin, startLoginByCookie, clearLoginError } from '../actions/auth';
import { getCookie } from '../service/Utilities';

import { ReactComponent as FSReaderLogo } from '../icons/logo/feedspot-reader.svg';

export class LoginPage extends React.Component {  

    constructor(props) {
        super(props);

        this.state = {
            // email: '',
            // password: '',
            // error: '',
            cookie: true,
            isLoggedIn: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.cookie !== prevState.cookie) {
            return {
                cookie: nextProps.cookie,
                isLoggedIn: nextProps.isLoggedIn
            };
        }
        // else if(nextProps.error !== prevState.error) {
        //     return {
        //         error: nextProps.error
        //     };
        // }
        // else if(!!nextProps.error){
        //     return {
        //         error: nextProps.error
        //     };
        // }
        return null
    }

    componentDidMount() {

        const fs_cookie = getCookie(process.env.FS_COOKIE_NAME);
        setTimeout(() => {
            if(!!fs_cookie) {
                this.setState(() => ({ 
                    cookie: true,
                }));
                const authData = {
                    cookie: fs_cookie,
                    redirect: this.props.fromPath
                };
                this.props.startLoginByCookie(authData);
            }
            else {
                this.setState(() => ({ 
                    cookie: false,
                }));
    
                if(process.env.MODE != "local")
                {
                    // const url = `${process.env.AUTH_BASE_URL}login?product=reader`;
                    const url = `${process.env.OLD_BASE_URL}?product=reader`;
                    window.open(url,"_self")
                }
                else {
                    alert('development mode set cookie');
                }
            }
            
        }, 2000);
    }

    // onEmailChange = (e) => {
    //     const email = e.target.value;
    //     this.setState(() => ({
    //         email 
    //     }));
    //     // this.props.clearLoginError();
    // };

    // onPasswordChange = (e) => {
    //     const password = e.target.value;
    //     this.setState(() => ({
    //         password 
    //     }));
    //     // this.props.clearLoginError();
    // };
    
    // onSubmit = (e) => {
    //     e.preventDefault();

    //     if (!this.state.email || !this.state.password) {
    //         this.props.clearLoginError('Please provide email and password.');
    //         // this.setState(() => ({ error: 'Please provide email and password.' }));
    //     } else {
    //         this.setState(() => ({ error: '' }));
    //         const authData = {
    //             email: this.state.email,
    //             password: this.state.password,
    //             redirect: this.props.fromPath
    //         };
    //         this.props.startLogin(authData);
    //     }
    // };

    // onAlertDismiss = (e) => {
    //     // this.setState({ 
    //     //     error: '',
    //     //     errorDismiss: true,
    //     //  });
    //     this.props.clearLoginError();
    // };

    render() {
        // const isLoading = !!this.state.cookie || !!this.state.isLoggedIn;
        return (
            <div className="splashscreen" style={{"position":"absolute","top":"50%","right":"50%","transform":"translate(50%,-50%)","textAlign":"center","display":"flex","justifyContent":"center","alignItems":"center","height":"100vh","width":"100vw"}}>
                <FSReaderLogo style={{"width":"100%","maxWidth":"400px", "padding":"1rem"}}/>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    cookie: !!state.auth.cookie,
    // error: state.auth.error,
    isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    startLogin: (authData) => dispatch(startLogin(authData)),
    startLoginByCookie: (authCookie) => dispatch(startLoginByCookie(authCookie)),
    // clearLoginError: (error) => dispatch(clearLoginError(error)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);