import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import userReducer from '../reducers/user';
import commonReducer from '../reducers/common';
import mainReducer from '../reducers/main';
import podcastReducer from '../reducers/podcast';
import teamsReducer from '../reducers/teams';
// import { history } from '../router/AppRouter';

const composeEnhancers = (['local','development'].includes(process.env.MODE) && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25 
})) || compose;

export default () => {

    const store = createStore(
        combineReducers({
            auth: authReducer,
            user: userReducer,
            common: commonReducer,
            main: mainReducer,
            podcast: podcastReducer,
            teams:teamsReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );

    // const unsubscribe = store.subscribe(() => {
    //     {console.log(history.location.pathname)}
    // });
  
    return store;
};