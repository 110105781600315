import React from 'react';
// import React, {Suspense} from 'react';
// import { Spinner  } from 'react-bootstrap';

export default class NotFoundPage extends React.Component {

  render() {
    return (
      <>
      {/* <Suspense fallback={<Spinner animation="border" variant="primary" size="sm" />}> */}
      <div className="error">
      <div className="fof">
        <h1>Error 404</h1>
      </div>
      </div>
      {/* </Suspense> */}
      </>
    );
  }
  
}