import { callApi, getHistoryAccountApi } from '../service/Api';
import { logout } from './auth';
import { history } from '../router/AppRouter';
import { ApiConstants } from '../constants/api';
import { callToast, isApiSuccess } from '../service/Utilities';
import { actionToDo } from './subscription';
import { updateUserPref as updateUserPrefSelect, updateUserPrefMultiple, mapPrefKeyValue, updateUserPrefMultipleByName } from '../selectors/userPreferences';
// import { actionUpdateEntries } from './subscription';
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/browser";
import Hotjar from '@hotjar/browser';

export const setUserInfo = ({ info = "" } = {}) => ({
    type: 'SET_USER_INFO',
    info
});

export const setSubmodal = (submodal = {}) => {
    return ({
        type: 'SET_SUBMODAL',
        submodal
    });
}

export const startSetUserInfo = () => {
    return (dispatch, getState) => {

        const apiData = {
            url: ApiConstants.USER_INFO,
            token: getState().auth.token,
            data: {
                access_token: getState().auth.token,
                // debug:1
            }
        }

        callApi(apiData)
        .then(res => {
            if(isApiSuccess(res.status)) {
                dispatch(setUserInfo({
                    info: res.info
                }));
                var userID = res.info.user_id || "unknown";
                Sentry.setUser({ 
                    username: userID,
                });
                if(process.env.HOTJAR_ENABLE)
                {
                    if (Hotjar.isReady()) {
                        Hotjar.identify(userID, {
                            product: "FS Beta",
                        });
                    }
                }
                    
                const show_payment_due = res.info.payment_due.modal || false;
                if(!!show_payment_due)
                {
                    dispatch(actionToDo({type:'OPEN_UPDATE_CARD_HOME_MODAL'}))
                }

            }
            else {
                dispatch(logout());
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
        
    };
};

export const setUserPref = ({ pref = "" } = {}) => ({
    type: 'SET_USER_PREF',
    pref
});

export const startSetUserPref = () => {
    return (dispatch, getState) => {

        const apiData = {
            url: ApiConstants.USER_PREF,
            token: getState().auth.token
        }

        callApi(apiData)
        .then(res => {
            if(isApiSuccess(res.status)) {
                dispatch(setUserPref({
                    pref: res.data.preferences
                }));
            }
            else {
                dispatch(logout());
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
        
    };
};

export const updateUserPref = (pref = []) => ({
    type: 'UPDATE_USER_PREF',
    pref
});

// export const startUpdateUserPref = ({key,value}) => {
    // console.log('startUpdateUserPref',key,value)
    // return (dispatch, getState) => {
export const startUpdateUserPref = ({key,value,showToast=false}) => (dispatch, getState) => new Promise((resolve, reject) => {
        const apiData = {
            url: ApiConstants.USER_PREF_UPDATE,
            token: getState().auth.token,
            method: 'POST',
            data: {
                [key]: value
            }
        }

        callApi(apiData)
        .then(res => {
            if(isApiSuccess(res.status)) {
                // console.log('xxx',getState().user.pref)
                // console.log('ttt',key,value)
                dispatch(updateUserPref(updateUserPrefSelect({key,value}, getState().user.pref)));
                if(showToast)
                {
                    callToast({type:"success",msg:"Updated Successfully"});
                }
                resolve();
            }
            else {
                if(res.msg) { callToast({type:"error",msg:res.msg}) }
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
        
});

// export const startUpdateUserPrefMultiple = (prefArr = []) => {
    // console.log('startUpdateUserPref',key,value)
    // return (dispatch, getState) => {
export const startUpdateUserPrefMultiple = (prefArr = []) => (dispatch, getState) => new Promise((resolve, reject) => {

        // console.log('prefArr', prefArr)
        // console.log('mapPrefKeyValue', mapPrefKeyValue(prefArr))

        const apiData = {
            url: ApiConstants.USER_PREF_UPDATE,
            token: getState().auth.token,
            method: 'POST',
            data: mapPrefKeyValue(prefArr)
        }

        callApi(apiData)
        .then(res => {
            if(isApiSuccess(res.status)) {
                // console.log('selector', updateUserPrefMultiple(prefArr, getState().user.pref));
                dispatch(updateUserPref(updateUserPrefMultiple(prefArr, getState().user.pref)));
                resolve();
            }
            else {
                if(res.msg) { callToast({type:"error",msg:res.msg}) }
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
        
});

export const startUpdateProfileApi = (profileData) => (dispatch, getState) => new Promise((resolve, reject) => {

    const apiData = {
        url: ApiConstants.UPDATE_PROFILE,
        token: getState().auth.token,
        method: 'POST',
        isFormdata: true,
        files: ['image'],
        // data: { name, website,blog, youtube_channel, bio, image, new_password}
        data: { ...profileData }
    }

    callApi(apiData)
    .then(res => {
        if(isApiSuccess(res.status)) {
            // let prefArr = [];
            if(!!res.data.user_pref && res.data.user_pref.length>0) {
                // for (const [key, value] of res.data) {
                //     prefArr.push({key,value});
                // }
                dispatch(updateUserPref(updateUserPrefMultiple(res.data.user_pref, getState().user.pref)));
            }
            if(!!res.data.user_info) {
                dispatch(setUserInfo({
                    info: {
                        ...getState().user.info,
                        ...res.data.user_info
                    }
                }));
            }
            callToast({type:"success",msg:"Saved Successfully"});
            resolve();
        }
        else {
            if(res.msg) { callToast({type:"error",msg:res.msg}) }
        }
    })
    
});

export const startVerifyEmailApi = ({otp, email}) => (dispatch, getState) => new Promise((resolve, reject) => {

    const apiData = {
        url: ApiConstants.VERIFY_EMAIL,
        token: getState().auth.token,
        method: 'POST',
        data: { otp, email }
    }

    callApi(apiData)
    .then(res => {
        if(isApiSuccess(res.status)) {
            if(!!res.data.userinfo) {
                dispatch(setUserInfo({
                    info: {
                        ...getState().user.info,
                        ...res.data.userinfo
                    }
                }));
            }
            callToast({type:"success",msg:"Updated Successfully"});
            resolve(res.data);
        }
        else {
            if(res.msg) { callToast({type:"error",msg:res.msg}) }
        }
    })
    
});

export const manageRouteChange = ({route}) => (dispatch, getState) => new Promise((resolve, reject) => {
    let newRoute = '/'+route;
    if(window.location.pathname != newRoute)
    {
        // console.log('manageRouteChange newRoute',newRoute)
    //   window.history.replaceState(null, null, newRoute);
      setTimeout(() => {
        if(window.location.pathname != newRoute)
        {
            // console.log('xxx', newRoute.split('/'))

            // console.log('yy',newRoute.split('/').slice(2).join('/'));
            // let x = newRoute.split('/').includes(process.env.APP_BASENAME)?newRoute.split('/').slice(2).join('/'):newRoute;
            window.history.replaceState(null, null, newRoute);
            // history.push(x);
        }
      }, 100);
      // ReactGA.pageview(window.location.pathname + window.location.search)
    //   ReactGA.pageview(newRoute);
    
    // console.log('pageview', { hitType: "pageview", newRoute })
      ReactGA.send({ hitType: "pageview", page: newRoute });
      getHistoryAccountApi({url:newRoute, token:getState().auth.token});
    //   .then((res) => {
    //     if(isApiSuccess(res.status)) {
    //       resolve(res.data);
    //     }
    //     else {
    //         if(res.msg) { callToast({type:"error",msg:res.msg}) }
    //     }
    //   });
    }
  });

export const startUpdatePassword = ({password}) => (dispatch, getState) => new Promise((resolve, reject) => {

    const apiData = {
        url: ApiConstants.UPDATE_PASSWORD,
        token: getState().auth.token,
        method: 'POST',
        data: {
            new_password:password
        }
    }
    callApi(apiData)
    .then(res => {
        if(isApiSuccess(res.status)) {
            const { preferences=[] } = res.data;
            if(preferences.length>0){
                dispatch(updateUserPref(updateUserPrefMultipleByName(preferences, getState().user.pref)));
            }
            resolve(res);
        }
        else {
            if(res.msg) { callToast({type:"error",msg:res.msg}) }
        }
    })
    
});

export const startSubmitFeedback = ({rating,message}) => (dispatch, getState) => new Promise((resolve, reject) => {

    const apiData = {
        url: ApiConstants.FEEDBACK,
        token: getState().auth.token,
        method: 'POST',
        data: {
            rating,
            message
        }
    }
    callApi(apiData)
    .then(res => {
        if(isApiSuccess(res.status)) {
            const { preferences=[] } = res.data;
            if(preferences.length>0){
                dispatch(updateUserPref(updateUserPrefMultipleByName(preferences, getState().user.pref)));
            }
            callToast({type:"success",msg:"Feedback submitted successfully."})
            resolve(res.data);
        }
        else {
            if(res.msg) { callToast({type:"error",msg:res.msg}) }
        }
    })
    
});