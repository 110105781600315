const FS_V1_BASE_URL = process.env.API_AUTH_ENDPOINT;
const FS_V2_BASE_URL = process.env.API_AUTH_ENDPOINT_2;


export const ApiConstants = {
    // Auth
    AUTH_ACCESS_TOKEN:      `${FS_V1_BASE_URL}auth/accesstoken.json`,
    // AUTH_COOKIE:            `${FS_V1_BASE_URL}tokenbycookie.json`,
    AUTH_COOKIE:            `${FS_V2_BASE_URL}tokenbycookie`,
    AUTH_LOGOUT:            `${FS_V1_BASE_URL}logout.json`,

    // User
    // USER_INFO:              `${FS_V1_BASE_URL}userinfo.json`,
    USER_INFO:              `${FS_V2_BASE_URL}profile/userinfo`,
    // USER_PREF:              `${FS_V1_BASE_URL}preference/list.json`,
    USER_PREF:              `${FS_V2_BASE_URL}preferences/general`,
    // USER_PREF_UPDATE:       `${FS_V1_BASE_URL}preference/update.json`,
    USER_PREF_UPDATE:       `${FS_V2_BASE_URL}preferences/general`,

    // Digest
    // DD_PREF:                `${FS_V1_BASE_URL}preference/ddlist.json`,
    DD_PREF:                `${FS_V2_BASE_URL}preferences/digest`,
    // DD_UPDATE:              `${FS_V1_BASE_URL}preference/ddupdate.json`,
    DD_UPDATE:              `${FS_V2_BASE_URL}preferences/digest`,

    // Common
    CATEGORY_LIST:          `${FS_V1_BASE_URL}category/list.json`,
    // SUGGESTION:             `${FS_V1_BASE_URL}suggest.json`,

    // Spotlight
    // SPOTLIGHT_LIST:         `${FS_V1_BASE_URL}spotlight/list.json`,
    SPOTLIGHT_LIST:         `${FS_V2_BASE_URL}spotlight/search`,
    SPOTLIGHT_DEFAULT:      `${FS_V2_BASE_URL}spotlight/default`,
    SUGGESTION:             `${FS_V2_BASE_URL}spotlight/suggest`,
    

    // Subscriptions
    // SUBSCRIPTION_LIST:      `${FS_V1_BASE_URL}subscription/list.json`, 
    SUBSCRIPTION_LIST:      `${FS_V2_BASE_URL}sites/list`, 
    // SUBSCRIPTION_SORT:      `${FS_V1_BASE_URL}subscription/sort.json`, 
    SUBSCRIPTION_SORT:      `${FS_V2_BASE_URL}sites/sort`, 
    SUBSCRIPTION_SORT_FO:   `${FS_V2_BASE_URL}folders/sites/sort`, 
    // SUBSCRIPTION_MOVE:      `${FS_V1_BASE_URL}subscription/move.json`,
    SUBSCRIPTION_MOVE:      `${FS_V2_BASE_URL}organize/move`,
    

    // Folders
    // FOLDER_DETAIL:          `${FS_V1_BASE_URL}folders/folderid.json`, 
    FOLDER_DETAIL:          `${FS_V2_BASE_URL}folders/detail`,
    // FOLDER_CREATE:          `${FS_V1_BASE_URL}folders/create.json`,
    FOLDER_CREATE:          `${FS_V2_BASE_URL}folders/create`,
    // FOLDER_RENAME:          `${FS_V1_BASE_URL}folders/rename.json`,
    FOLDER_RENAME:          `${FS_V2_BASE_URL}folders/rename`,
    // FOLDER_DELETE:          `${FS_V1_BASE_URL}folders/delete.json`,
    FOLDER_DELETE:          `${FS_V2_BASE_URL}folders/delete`,
    // FOLDER_FOLLOW:          `${FS_V1_BASE_URL}folders/follow.json`,
    FOLDER_FOLLOW:          `${FS_V2_BASE_URL}folders/follow`,
    FOLDER_ADD_SITE:        `${FS_V2_BASE_URL}folders/sites/add`,

    // Feeds
    // FEED_RENAME:            `${FS_V1_BASE_URL}feed/rename.json`,
    FEED_RENAME:            `${FS_V2_BASE_URL}sites/rename`,
    // FEED_DELETE:            `${FS_V1_BASE_URL}feed/delete.json`,
    FEED_DELETE:            `${FS_V2_BASE_URL}sites/unfollow`,
    // FEED_INFO:              `${FS_V1_BASE_URL}feed/detail.json`,
    FEED_INFO:              `${FS_V2_BASE_URL}sites/detail`,
    // FEED_ADD:               `${FS_V1_BASE_URL}feed/create.json`,
    FEED_ADD:               `${FS_V2_BASE_URL}sites/follow`,

    // Tags
    TAG_RENAME:             `${FS_V1_BASE_URL}tags/rename.json`,
    TAG_DELETE:             `${FS_V1_BASE_URL}tags/delete.json`,
    TAG_LIST:               `${FS_V1_BASE_URL}tags/list.json`,
    TAG_ADD:                `${FS_V1_BASE_URL}tags/add.json`,

    // Entries
    // ENTRIES:                `${FS_V1_BASE_URL}entries.json`,
    ENTRIES:                `${FS_V2_BASE_URL}entries`,

    // Count
    UNREAD_COUNT:           `${FS_V2_BASE_URL}unreadcount`,

    // Markers
    // MARK_READ_FEED:         `${FS_V1_BASE_URL}markers/readfeed.json`,
    // MARK_READ_FOLDER:       `${FS_V1_BASE_URL}markers/readfolder.json`,
    // MARK_READ_ENTRY:        `${FS_V1_BASE_URL}markers/entries.json`,
    MARK_READ:              `${FS_V2_BASE_URL}markers/markasread`,
    MARK_UNREAD:            `${FS_V2_BASE_URL}markers/markasunread`,

    // Sharing
    // SHARE:                  `${FS_V1_BASE_URL}share.json`,
    SHARE:                  `${FS_V2_BASE_URL}saving/save`,
    // SHARE_LIST:             `${FS_V1_BASE_URL}share/list.json`,
    SHARE_LIST:             `${FS_V2_BASE_URL}preferences/sharing`,
    SHARE_SAVE_ACCOUNT:     `${FS_V1_BASE_URL}share/saveaccount.json`,
    SHARE_PERSONALIZE:      `${FS_V1_BASE_URL}share/order.json`,
    SHARE_NETWORKS:         `${FS_V2_BASE_URL}sharing/listnetworks`,
    SHARE_NETWORKS_SORT:    `${FS_V2_BASE_URL}sharing/sort`,
    SHARE_NETWORKS_REMOVE:  `${FS_V2_BASE_URL}saving/disconnect`,
    SHARE_NETWORKS_BOOKS:   `${FS_V2_BASE_URL}saving/list`,

    // Scheduler
    // SCHEDULER_ACCOUNT:      `${FS_V1_BASE_URL}scheduler/accounts.json`,
    SCHEDULER_ACCOUNT:      `${FS_V2_BASE_URL}scheduler/accounts`,
    // SCHEDULER_POST:         `${FS_V1_BASE_URL}scheduler/schedule.json`,
    SCHEDULER_POST:         `${FS_V2_BASE_URL}scheduler/schedule`,

    // Favorite
    // FAVORITE:               `${FS_V1_BASE_URL}favorite.json`,
    FAVORITE_ADD:           `${FS_V2_BASE_URL}favorites/add`,
    FAVORITE_REMOVE:        `${FS_V2_BASE_URL}favorites/remove`,

    // Pinboards
    // PINBOARD_LIST:          `${FS_V1_BASE_URL}pinboard/list.json`,
    PINBOARD_LIST:          `${FS_V2_BASE_URL}pinboards/list`,
    // PINBOARD_CREATE:        `${FS_V1_BASE_URL}pinboard/create.json`,
    PINBOARD_CREATE:        `${FS_V2_BASE_URL}pinboards/create`,
    // PINBOARD_RENAME:        `${FS_V1_BASE_URL}pinboard/update.json`,
    PINBOARD_RENAME:        `${FS_V2_BASE_URL}pinboards/rename`,
    // PINBOARD_DELETE:        `${FS_V1_BASE_URL}pinboard/delete.json`,
    PINBOARD_DELETE:        `${FS_V2_BASE_URL}pinboards/delete`,
    // PINBOARD_ADD:           `${FS_V1_BASE_URL}pinboard/savearticle.json`,
    PINBOARD_ADD:           `${FS_V2_BASE_URL}pinboards/entries/add`,
    // PINBOARD_REMOVE:        `${FS_V1_BASE_URL}pinboard/deletearticle.json`,
    PINBOARD_REMOVE:        `${FS_V2_BASE_URL}pinboards/entries/remove`,
    PINBOARD_ARTICLES:      `${FS_V1_BASE_URL}pinboard/articles.json`,
    PINBOARD_LOG:           `${FS_V1_BASE_URL}pinboard/log.json`,

    //Teams pinboards
    PINBOARD_REQUEST_DATA:  `${FS_V2_BASE_URL}teams/pinboard/get_request`,
    PINBOARD_MANAGE_REQUEST:`${FS_V2_BASE_URL}teams/pinboard/manage_request`,
    PINBOARD_FOLLOW:        `${FS_V2_BASE_URL}teams/pinboard/request`,
    PINBOARD_GET_PINBOARD:  `${FS_V2_BASE_URL}teams/pinboard/get_pinboards`,
    PINBOARD_MEMBERS:       `${FS_V2_BASE_URL}teams/pinboard/members`,
    PINBOARD_ADD_MEMBER:    `${FS_V2_BASE_URL}teams/pinboard/add_member`,
    PINBOARD_DELETE_PINBOARD:`${FS_V2_BASE_URL}teams/pinboard/delete_pinboard`,
    PINBOARD_RENAME_PINBOARD:`${FS_V2_BASE_URL}teams/pinboard/rename_pinboard`,
    PINBOARD_PIN_ARTICLE:    `${FS_V2_BASE_URL}teams/pinboard/pin_article`,
    PINBOARD_REMOVE_ARTICLE: `${FS_V2_BASE_URL}teams/pinboard/remove_article`,
    PINBOARD_DELETE_MEMBER:  `${FS_V2_BASE_URL}teams/pinboard/delete_member`,
    PINBOARD_MANAGE_ROLE:    `${FS_V2_BASE_URL}teams/pinboard/manage_role`,
    PINBOARD_CREATE_PINBOARD:`${FS_V2_BASE_URL}teams/pinboard/create`,
    PINBOARD_MARK_AS_READ:    `${FS_V2_BASE_URL}teams/pinboard/markasread`,
    PINBOARD_MARK_AS_UNREAD:    `${FS_V2_BASE_URL}teams/pinboard/markasunread`,

    //####################################################################################### TEAMS FOLDERS
    // FOLDER_GET_DATA:            `${FS_V2_BASE_URL}teams/folder/get_folders`,
    // FOLDER_CREATE:              `${FS_V2_BASE_URL}teams/folder/create`,
    // // FOLDER_SEARCH_SITE:         `https://vasaganbeta.feedspot.com/publisher/guest/pages/search?q=`,
    // FOLDER_SEARCH_SITE:         `https://valetto.feedspot.com/publisher/guest/pages/search?q=`,
    // FOLDER_DELETE:              `${FS_V2_BASE_URL}teams/folder/delete_folder`,
    FOLDER_GET_DATA:            `https://api2.devteams.feedspot.com/v2/teams/folder/get_folders`,
    TEAM_FOLDER_CREATE:              `https://api2.devteams.feedspot.com/v2/teams/folder/create`,
    FOLDER_UPDATE:               `https://api2.devteams.feedspot.com/v2/teams/folder/update_folder`,
    FOLDER_DELETE:              `https://api2.devteams.feedspot.com/v2/teams/folder/delete_folder`,
    FOLDER_MEMBERS:             `https://api2.devteams.feedspot.com/v2/teams/folder/members`,
    FOLDER_DELETE_MEMBER:       `https://api2.devteams.feedspot.com/v2/teams/folder/delete_member`,
    FOLDER_MANAGE_ROLE:         `https://api2.devteams.feedspot.com/v2/teams/folder/manage_role`,
    FOLDER_ADD_MEMBER:          `https://api2.devteams.feedspot.com/v2/teams/folder/add_member`,
    FOLDER_FOLLOW:              `https://api2.devteams.feedspot.com/v2/teams/folder/request`,

    FOLDER_SEARCH_SITE:         `https://valetto.feedspot.com/publisher/guest/pages/search?q=`,

    // Accounts
    // UPDATE_PASSWORD:        `${FS_V1_BASE_URL}accounts/password.json`,
    UPDATE_PASSWORD:        `${FS_V2_BASE_URL}accounts/updatepassword`,
    UPDATE_EMAIL:           `${FS_V2_BASE_URL}accounts/updateemail`,
    VERIFY_EMAIL:           `${FS_V2_BASE_URL}accounts/verifyemail`,
    // UPDATE_PROFILE:         `${FS_V1_BASE_URL}accounts/profile.json`,
    UPDATE_PROFILE:         `${FS_V2_BASE_URL}profile/update`,
    // ACCOUNT_BILLING:        `${FS_V1_BASE_URL}accounts/profile.json`,
    ACCOUNT_BILLING:        `${FS_V2_BASE_URL}accounts/billing`,
    // ACCOUNT_DEACTIVATE:     `${FS_V1_BASE_URL}accounts/deactivate.json`,
    ACCOUNT_DEACTIVATE:     `${FS_V2_BASE_URL}accounts/deactivate`,
    ACCOUNT_HISTORY:        `${FS_V2_BASE_URL}accounts/visitedpage`,
    UPDATE_CARD:            `${FS_V2_BASE_URL}accounts/card/update`,
    

    // Payments
    SUBSCRIPTION_INFO:      `${FS_V2_BASE_URL}subscriptions/info`,
    SUBSCRIPTION_CREATE:    `${FS_V2_BASE_URL}subscriptions/create`,
    SUBSCRIPTION_CANCEL:    `${FS_V2_BASE_URL}subscriptions/cancel`,
    SUBSCRIPTION_VERIFY:    `${FS_V2_BASE_URL}subscriptions/verify`,
    SUBSCRIPTION_SWITCH:    `${FS_V2_BASE_URL}subscriptions/switch`,
    SUBSCRIPTION_CHANGE:    `${FS_V2_BASE_URL}subscriptions/change`,
    SUBSCRIPTION_RESUBSCRIBE:`${FS_V2_BASE_URL}subscriptions/resubscribe`,
    SUBSCRIPTION_EXTEND_TRIAL:`${FS_V2_BASE_URL}subscriptions/trial/extend`,
    SUBSCRIPTION_3DS:       `${FS_V2_BASE_URL}subscriptions/request/3ds`,
    SUBSCRIPTION_MODAL_INFO:`${FS_V2_BASE_URL}subscriptions/modal/info`,
    TEAMS_MODAL_INFO:       `${FS_V2_BASE_URL}subscriptions/modal/info`,
    SUBSCRIPTION_CHECKOUT:  `${FS_V2_BASE_URL}subscriptions/checkout`,
    PAYMENTS_TRY_NOW:       `${FS_V2_BASE_URL}invoice/pay/latest`,

    // Invoices
    INVOICE_LIST:           `${FS_V2_BASE_URL}invoice/list`,
    INVOICE_DOWNLOAD:       `${FS_V2_BASE_URL}invoice/download`,

    // Opml
    OPML_IMPORT:            `${FS_V2_BASE_URL}opml/import`,
    OPML_EXPORT:            `${FS_V2_BASE_URL}opml/export`,
    OPML_STATUS:            `${FS_V2_BASE_URL}opml/status`,

    //Teams
    TEAMS:                   `${FS_V2_BASE_URL}teams`,
    TEAM_CREATE:            `${FS_V2_BASE_URL}teams/create`,

    // Teams MEMBERS
    TEAM_ADD:               `${FS_V2_BASE_URL}teams/members/add`,
    TEAM_REMOVE:            `${FS_V2_BASE_URL}teams/members/remove`,
    TEAM_LIST:              `${FS_V2_BASE_URL}teams/members/list`,
    TEAM_INVITE:            `${FS_V2_BASE_URL}teams/members/invite`,
    TEAM_MEMBER_DELETE:     `${FS_V2_BASE_URL}teams/members/delete`,
    TEAM_MANAGE_ROLE:       `${FS_V2_BASE_URL}teams/members/manage_role`,
    TEAM_SEARCH:            `${FS_V2_BASE_URL}teams/members/search`,
    TEAM_ADD_MEMBER:        `${FS_V2_BASE_URL}teams/members/add_member`,
    TEAM_COUNT_LIMIT:       `${FS_V2_BASE_URL}teams/members/`,

    // Teams ACTIVITY
    TEAM_ACTIVITY:          `${FS_V2_BASE_URL}teams/activity`,

    //Teams Organisation
    TEAM_UPDATE:           `${FS_V2_BASE_URL}teams/organization/update_team`,

    // Search
    SEARCH_NAVBAR:          `${FS_V2_BASE_URL}search`,
    SEARCH_SITES:           `${FS_V2_BASE_URL}search/mysites`,

    // Podcast
    PODCAST_BROWSE:         `${FS_V2_BASE_URL}podcasts/browse`,
    PODCAST_CAT_LIST:       `${FS_V2_BASE_URL}podcasts/categories`,
    PODCAST_CAT_DETAIL:     `${FS_V2_BASE_URL}podcasts/categories/folders`,
    PODCAST_ENQUEUE:        `${FS_V2_BASE_URL}podcasts/enqueue`,
    PODCAST_DEQUEUE:        `${FS_V2_BASE_URL}podcasts/dequeue`,
    PODCAST_RECOMMENDED:    `${FS_V2_BASE_URL}podcasts/recommended`,
    PODCAST_PLAYLIST:       `${FS_V2_BASE_URL}podcasts/playlist`,
    
    // Organize
    ORGANIZE_LIST:          `${FS_V2_BASE_URL}organize`,
    ORGANIZE_DELETE:        `${FS_V2_BASE_URL}organize/manage`,
    ORGANIZE_MOVE:          `${FS_V2_BASE_URL}organize/movefeed`,
    ORGANIZE_SORT:          `${FS_V2_BASE_URL}organize/sort`,

    // Industry Packages
    INDUSTRY_LIST:          `${FS_V2_BASE_URL}industrypackages/list`,
    INDUSTRY_FEATURES:      `${FS_V2_BASE_URL}industrypackages/feature`,

    // React
    FEEDBACK:               `${FS_V2_BASE_URL}feedback`,


};