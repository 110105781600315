import { toast, cssTransition } from 'react-toastify';
// import ReactGA from 'react-ga'
// import ReactGA from "react-ga4";

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}
export function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function eraseCookie(name) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

// export function getCookie(name) { //ignore for test
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(';').shift();
// }

const customAnimationToast = cssTransition({
  enter: "custom-in-fwd",
  exit: "custom-out-bck d-none"
});

// test
export const callToast = ({
  type="info",
  msg,
  // position = "bottom-center",
  position = "bottom-left",
  // autoClose = 3000,
  autoClose = 1500,
  hideProgressBar = true,
  closeOnClick = true,
  pauseOnHover = true,
  pauseOnFocusLoss= false,
  draggable = true,
  progress = undefined,
  theme = "dark",
  // theme = "colored",
  icon = false, //disabled from toast container,
  // closeButton = false,
  closeButton = true,
  transition = customAnimationToast,
}) =>  {
  // let newtype = type == "success" ? 'info' : type;
  let newtype = type;
  let newIcon = false;

  return toast[newtype](msg, {
  position,
  autoClose,
  hideProgressBar,
  closeOnClick,
  pauseOnHover,
  pauseOnFocusLoss,
  draggable,
  progress,
  theme,
  closeButton,
  transition
})
};

// export const callToast = ({
//     type="success",
//     msg,
//     position = "top-right",
//     autoClose = 3000,
//     hideProgressBar = false,
//     closeOnClick = true,
//     pauseOnHover = true,
//     draggable = true,
//     progress = undefined,
  
//   }) =>  false;

export const isApiSuccess = (status) => status == 1;

export const FormatCash = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export const  truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
}

export const popupCenter = ({url, title, w=500, h=300}) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  // console.log('popupCenter', url)
  const newWindow = window.open(url, title, 
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  )

  if (window.focus) newWindow.focus();
  return newWindow;
}

export const createFormData = (formData, key, data, files=[]) => {
  if (files.includes(key)) {
    formData.append(key, data);
  } else if (data === Object(data) || Array.isArray(data)) {
    for (var i in data) {
      createFormData(formData, key + '[' + i + ']', data[i]);
    }
  } else {
    formData.append(key, data);
  }
}

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
);

export const timeConversion = (duration) => {
  const portions = [];

  const msInHour = 1000 * 60 * 60;
  const hours = Math.trunc(duration / msInHour);
  if (hours > 0) {
    portions.push(hours + 'h');
    duration = duration - (hours * msInHour);
  }

  const msInMinute = 1000 * 60;
  const minutes = Math.trunc(duration / msInMinute);
  if (minutes > 0) {
    portions.push(minutes + 'm');
    duration = duration - (minutes * msInMinute);
  }

  const seconds = Math.trunc(duration / 1000);
  if (seconds > 0) {
    portions.push(seconds + 's');
  }

  return portions.join(' ');
}

export const shuffleArr = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const copyToClipBoard = async copyMe => {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(copyMe);
      callToast({
        type:"success",
        msg:`Copied to Clipboard!`}
      );
    } catch (err) {
      callToast({
        type:"error",
        msg:`Failed to copy!`}
      );
    }
  } else {
    document.execCommand('copy', true, copyMe);
    callToast({
      type:"success",
      msg:`Copied to Clipboard!`}
    );
  }
};

export const wrapHTML = (el, wrapper) => {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

// export const parseImage = (htmlString, imgToolbarJSX) =>
// {
//   let parser = new DOMParser();
//   let doc = parser.parseFromString(htmlString, "text/html");

//   // let imgToolbarEl = ReactDOMServer.renderToStaticMarkup(imgToolbarJSX)

//   console.log('imgToolbarEl', imgToolbarJSX)

//   const imgWrapper = document.createElement('div');
//   imgWrapper.classList.add('img-wrapper');
//   imgWrapper.append(imgToolbarEl);

//   let images = doc.firstChild.getElementsByTagName('img');
//   for(let i =0; i<images.length; i++) {
//     images[i].classList.add('img-element');
//     wrapHTML(images[i], imgWrapper);
//   }
//   return doc.body.innerHTML;
// }

export const processContent = ({content=""}) => {
  let new_content = content;
  new_content = new_content.replace(/href/g, "target='_blank' href"); // links in new tab
  // new_content = parseImage(new_content, imgToolbarJSX); // parse images
  return new_content;
}

// export const manageRouteChange = (route) => {
//   let newRoute = '/'+route;
//   if(window.location.pathname != newRoute)
//   {
//     window.history.replaceState(null, null, newRoute);
//     // ReactGA.pageview(window.location.pathname + window.location.search)
//     ReactGA.pageview(newRoute)
//     getHistoryAccountApi
//     getDeactivateAccountApi({url:newRoute, token})
//     .then((res) => {
//         const { url } = res;
//         this.setState({deactivating:false});
//         window.open(url,"_self")
//     })
//   }
//   return true;
// }

export const preg_match =  (regex, str) => {
  return (new RegExp(regex).test(str))
}

export const getBlogTypeFromPermalink = (permalink) => {
  let type = 'blog';
  if(preg_match("_youtube_", permalink) || preg_match("_youtubers", permalink)){type='youtube';}
  else if(preg_match("_rss_", permalink) || preg_match("-rss-", permalink)){type='rss';}
  else if(preg_match("_magazine_", permalink) || preg_match("_magazine", permalink)){type='magazine';}
  else if(preg_match("_forums", permalink)){type='forums';}
  else if(preg_match("_podcast_", permalink) || preg_match("_podcast", permalink)){type='podcast';}
  else if(preg_match("_instagram_", permalink)){type='instagram';}
  else if(preg_match("_news_websites", permalink) || preg_match("_news", permalink) ){type='newswebsite';}
  else if(preg_match("_journals/", permalink)){type='journal';}
  else if(preg_match("_talk_shows/", permalink)){type='talkshows';}   
  return type;
}

export const getProPlanCountryCodes = () => {
  return ["US", "CA", "GB", "AU", "SG", "IE", "CH", "NO", "HK", "DK", "NL", "AT", "DE", "SE", "FR", "BE", "JP", "NZ"];
}

export const isTouch = "maxTouchPoints" in window.navigator && window.navigator.maxTouchPoints > 1;
// export const isTouch = true;

export const sendGA = ({type="event", eventCategory,eventLabel,eventAction="Click",eventValue=1,nonInteraction=true}) => {

  if(!['production'].includes(process.env.MODE))
  {
    console.log('GA DEV DEBUG =>', type, eventCategory, eventLabel, eventAction, eventValue, nonInteraction)
  }
  
  // new tag manager
  if (typeof window.gtag === 'undefined') return;
  try {
    window.gtag("event", eventCategory, {
      category: eventCategory,
      label: eventLabel,
      action: eventAction,
      value: eventValue,
      debug_mode:['production'].includes(process.env.MODE)?false:true,
    });
  } catch (e) {}  
  return true;
  // ReactGA.event({
  //   category: "your category",
  //   action: "your action",
  //   label: "your label", // optional
  //   value: 99, // optional, must be a number
  //   nonInteraction: true, // optional, true/false
  //   transport: "xhr", // optional, beacon/xhr/image
  // });
}

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const truncateWithEllipses = (text, max=100) => text.substr(0,max-1)+(text.length>max?'...':'');

