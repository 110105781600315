export const getPref = (key, preferences = [], pick = false) => {
    let pref =  preferences
    .filter(item => item.key === key);
    if(pref.length==0) {
        // return pref;
        return "";
    }
    else if(pick) {
        return pref[0][pick];
    }
    else {
        return pref[0];
    }
}

export const getPrefByName = (name, preferences = [], pick = false) => {
    let pref =  preferences
    .filter(item => item.name === name);
    if(pref.length==0) {
        // return pref;
        return "";
    }
    else if(pick) {
        return pref[0][pick];
    }
    else {
        return pref[0];
    }
}

export const updateUserPref = ({key, value}, pref = []) => {
    return pref
    .map((item, index) => item.name == key ? { ...item, name:key, value } : item)
}
export const mapPrefKeyValue = (prefArr = []) => {
    return prefArr.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {})
}

export const updateUserPrefMultiple = (prefArrObj, pref = []) => {
    return pref.map(el => {
        let key = "key";
        let found = prefArrObj.find(s => s[key] === el[key]);
        if (found) {
            // el = Object.assign(el, found);
            el = { ...el, ...found }
        }
        return el;
      });
}

export const updateUserPrefMultipleByName = (prefArrObj, pref = []) => {
    return pref.map(el => {
        let key = "name";
        let found = prefArrObj.find(s => s[key] === el[key]);
        if (found) {
            // el = Object.assign(el, found);
            el = { ...el, ...found }
        }
        return el;
      });
}

// export const updateUserPrefMultiple = (prefArrObj, pref = []) => {
//     return pref.map((item, index) => Object.entries(prefArrObj).map(([k, value], i) => item.key == k ? {...item , value} : item));
// }