const commonReducerDefaultState = {
    navCategory: "",
    // feedInfo: "",
    socialList: [],
    shareNetworks: [],
};

export default (state = commonReducerDefaultState, action) => {
    // console.log("COMMON",action.type)
    switch (action.type) {
        case 'LOGOUT':
            return {
                ...commonReducerDefaultState
            };
        case 'SET_NAV_CATEGORY':
            return {
                ...state,
                ...action.navCategory,
            };
        case 'SET_SHARE_LIST':
            return {
                ...state,
                socialList: action.list,
            };
        case 'SET_SHARE_NETWORKS':
            return {
                ...state,
                shareNetworks: action.list,
            };
        default:
            return state;
    }
};