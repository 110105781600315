import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({
    isAuthenticated,
    component: Component,
    fromPath,
    ...rest
}) => (
    <Route {...rest} component={(props) => (
        isAuthenticated ? (
        <Redirect to={fromPath=="/"?"/all":fromPath} />
        ) : (
        <Component {...props} fromPath={fromPath} />
        )
    )} />
);

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.isLoggedIn
});

export default connect(mapStateToProps)(PublicRoute);