

import { getCookie } from '../service/Utilities';

const hide_twc_sm = getCookie('hide_twc_sm');

const mainReducerDefaultState = {
    subscription: [],
    pinboards: [],
    selected: {
        type: "all",
        id: "view_all",
        name: "All Post",
        key: "view_all",
        page: 'all',
        force: false
    },
    action_entries: {},
    action_to_do: {},
    submodal: {
        closed: !!hide_twc_sm && hide_twc_sm=="on" ? true : false,
    }
};

export default (state = mainReducerDefaultState, action) => {
    // console.log("USER",action.type)
    switch (action.type) {
        case 'LOGOUT':
            return {
                ...mainReducerDefaultState
            };
        case 'SET_SUBSCRIPTION':
            return {
                ...state,
                subscription: action.subscription
            };
        case 'SET_SUBMODAL':
            return {
                ...state,
                submodal: action.submodal
            };
        case 'UPDATE_SUBSCRIPTION':
            return {
                ...state,
                subscription: action.subscription
            };
        case 'UPDATE_SELECTED':
            return {
                ...state,
                selected: action.selected
            };
        case 'ACTION_UPDATE_ENTRIES':
            return {
                ...state,
                action_entries: action.updates
            };
        case 'SET_PINBOARDS':
            return {
                ...state,
                pinboards: action.pinboards
            };
        case 'ACTION_TO_DO':
            return {
                ...state,
                action_to_do: action.to_do
            };
        case 'UPDATE_STATUS':
            // console.log('action.payload')
            return {
                ...state,
                action_to_do: {
                    ...state.action_to_do,
                    detailModal: state.action_to_do.detailModal.map((item, index) => 
                        index === 0 ? { ...item, status: action.payload } : item
                    )
                }
            };
        default:
            return state;
    }
};