import React, { useEffect, lazy } from 'react';
// import React, {Suspense, lazy} from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
// import { Spinner } from 'react-bootstrap';
import LoginPage from "../container/LoginPage";

// import HomePage from "../container/HomePage";
// import SpotlightPage from "../container/SpotlightPage";
// import SettingsPage from "../container/SettingsPage";
// import PaymentsPage from "../container/PaymentsPage";
// import PodcastPage from "../container/PodcastPage";
// import OrganizePage from "../container/OrganizePage";
// import IndustryPackagesPage from "../container/IndustryPackagesPage";
// import AccountsPage from "../container/AccountsPage";
import NotFoundPage from '../container/NotFoundPage';

// import { manageRouteChange } from '../actions/user';
// import ReactGA from 'react-ga'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.GA4_TRACKING_ID)
// ReactGA.initialize(process.env.GA_TRACKING_ID, {
//     'cookieDomain': 'auto',
//     'debug': process.env.MODE == "production" ? false : true,
// });

// const LoginPage = lazy(() => import('../container/LoginPage'))
const HomePage = lazy(() => import('../container/HomePage'))
const SpotlightPage = lazy(() => import('../container/SpotlightPage'))
const SettingsPage = lazy(() => import('../container/SettingsPage'))
const PaymentsPage = lazy(() => import('../container/PaymentsPage'))
const PodcastPage = lazy(() => import('../container/PodcastPage'))
const OrganizePage = lazy(() => import('../container/OrganizePage'))
const IndustryPackagesPage = lazy(() => import('../container/IndustryPackagesPage'))
const TeamsPage = lazy(() => import('../container/TeamsPage'))
const TeamsJoinPage = lazy(() => import('../container/TeamsJoinPage'))

// const NotFoundPage = lazy(() => import('../container/NotFoundPage'))

// const UserEditPage = lazy(() => import('../container/UserEditPage'))
// const NotFoundPage = lazy(() => import('../container/NotFoundPage'))

// export const history = createBrowserHistory();
export const history = createBrowserHistory({ basename: `/${process.env.APP_BASENAME}` });

const AppRouter = () => {

    // const initRoute = history.location.pathname;

    // console.log('AppRouter useEffect', window.location.pathname + window.location.search)
    let initialPage = window.location.pathname + window.location.search;

    useEffect(() => {
        if(
            (process.env.MODE == "production" && window.location.host == "beta.feedspot.com") ||
            (process.env.MODE == "staging" && window.location.host == "staging.feedspot.com") || 
            (process.env.MODE == "development" && window.location.host == "valettobeta.feedspot.com")
        )
        {
            window.open(`${process.env.BASE_URL}${process.env.APP_BASENAME}?product=reader`,"_self");
        }
    }, []);

    useEffect(() => {
        // console.log('AppRouter useEffect', window.location.pathname + window.location.search)
        // ReactGA.pageview(window.location.pathname + window.location.search) // initial url
        // const newRoute = window.location.pathname;
        // ReactGA.pageview(window.location.pathname + window.location.search) // initial url

        // console.log('pageview', { hitType: "pageview", page: window.location.pathname + window.location.search })
        // ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        ReactGA.send({ hitType: "pageview", page: initialPage });
        // console.log('pageview', { hitType: "pageview", page: initialPage })


        // history.listen(location => {
        //     console.log('AppRouter history.listen', location.pathname + location.search)
        //     ReactGA.pageview(location.pathname + location.search)
        // })
    });
    
    // console.log(history.location.pathname)
    
    return (
        <Router history={history}>
            {/* <Suspense fallback={<Spinner animation="border" variant="primary" size="sm" />}> */}
                <Switch>
                    <PublicRoute path="/" component={LoginPage} fromPath={history.location.pathname} exact={true} />
                    <PrivateRoute path="/all" component={HomePage} />
                    

                    <PrivateRoute path="/teams/join/:teamId" component={TeamsJoinPage} routeName="pb" />

                    <PrivateRoute path="/fo/:folderId([0-9]+)/fe/:feedId([0-9]+)" component={HomePage} routeName="fo_fe" followFeed={initialPage?.includes('followfeed')} />
                    <PrivateRoute path="/fe/:feedId([0-9]+)" component={HomePage} routeName="fe" followFeed={initialPage?.includes('followfeed')} />
                    <PrivateRoute path="/fo/:folderId([0-9]+)" component={HomePage} routeName="fo" followFeed={initialPage?.includes('followfeed')} />
                    <PrivateRoute path="/pinboard/:pinboardId([0-9]+)" component={HomePage} routeName="pb" />

                    <PrivateRoute path="/podcast/:podcastCategory/:folderId([0-9]+)/:feedId([0-9]+)" component={PodcastPage} routeName="pod_3" />
                    <PrivateRoute path="/podcast/:podcastCategory/:folderId([0-9]+)" component={PodcastPage} routeName="pod_2" />
                    <PrivateRoute path="/podcast/:podcastCategory" component={PodcastPage} routeName="pod_1" />
                    <PrivateRoute path="/podcast" component={PodcastPage} routeName="pod_0" />

                    <PrivateRoute path="/favorite" component={HomePage} routeName="favorite" />
                    <PrivateRoute path="/spotlight" component={SpotlightPage} />

                    <PrivateRoute path="/teams" component={TeamsPage} />
                    <PrivateRoute path="/teams/pinboard/:pinboardId([A-Za-z0-9=]+)" component={HomePage} />
                    <PrivateRoute path="teams/fo/:folderId([A-Za-z0-9=]+)/fe/:feedId([A-Za-z0-9=]+)" component={HomePage} routeName="fo_fe" followFeed={initialPage?.includes('followfeed')} />
                    <PrivateRoute path="teams/fo/:folderId([A-Za-z0-9=]+)" component={HomePage} routeName="fo" followFeed={initialPage?.includes('followfeed')} />

                    <PrivateRoute path="/settings/backup/dropbox" component={SettingsPage} routeName="settings_2" />
                    <PrivateRoute path="/settings/profile/edit" component={SettingsPage} routeName="settings_4" />
                    <PrivateRoute path="/settings/profile/password" component={SettingsPage} routeName="settings_5" />
                    <PrivateRoute path="/settings/accounts/deactivate" component={SettingsPage} routeName="settings_13" />
                    <PrivateRoute path="/settings/accounts/social" component={SettingsPage} routeName="settings_9" />
                    <PrivateRoute path="/settings/digest/team" component={SettingsPage} routeName="settings_15" />
                    <PrivateRoute path="/settings/digest/customize" component={SettingsPage} routeName="settings_16" />
                    <PrivateRoute path="/settings/digest/organize" component={SettingsPage} routeName="settings_17" />
                    <PrivateRoute path="/settings/digest/personalize" component={SettingsPage} routeName="settings_18" />
                    <PrivateRoute path="/settings/digest/unsubscribe" component={SettingsPage} routeName="settings_20" />
                    <PrivateRoute path="/settings/digest" component={SettingsPage} routeName="settings_14" />
                    {/* <PrivateRoute path="/settings/accounts/deactivate/confirm" component={SettingsPage} routeName="settings_19" /> */}
                    <PrivateRoute path="/settings/apps" component={SettingsPage} routeName="settings_6" />
                    <PrivateRoute path="/settings/opml" component={SettingsPage} routeName="settings_7" />
                    <PrivateRoute path="/settings/publish" component={SettingsPage} routeName="settings_8" />
                    <PrivateRoute path="/settings/sharing" component={SettingsPage} routeName="settings_10" />
                    <PrivateRoute path="/settings/rss" component={SettingsPage} routeName="settings_11" />
                    <PrivateRoute path="/settings/notifications" component={SettingsPage} routeName="settings_12" />
                    <PrivateRoute path="/settings" component={SettingsPage} />
                    <PrivateRoute path="/billing" component={SettingsPage} routeName="settings_3" />

                    <PrivateRoute path="/marketers" component={PaymentsPage} />
                    <PrivateRoute path="/organize" component={OrganizePage} />
                    <PrivateRoute path="/industry-packages" component={IndustryPackagesPage} />
                    {/* {["profile", "digest", "apps", "social", "sharing", "notifications", "billing", "delete"].map((path, index) => 
                        <PrivateRoute path={`/account/${path}`} component={AccountsPage} key={index} />
                    )} */}
                    <Route component={NotFoundPage} />
                </Switch>
            {/* </Suspense> */}
        </Router>
    );
}

export default AppRouter;
