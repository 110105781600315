const podcastReducerDefaultState = {
    player: {
        entry: {},
    },
    playerSetting: {
        shuffle: 0,
        repeat: 2,
    },
    playerStatus: 'STOP',
    playlistLoaded: false,
    playlist: [],
    continue: false,
};

export default (state = podcastReducerDefaultState, action) => {
    // console.log("USER",action.type)
    switch (action.type) {
        case 'SET_PODCAST_PLAYLIST':
            return {
                ...state,
                playlistLoaded: true,
                playlist: action.playlist,
            };
        case 'SET_PODCAST_PLAYER':
            return {
                ...state,
                playerStatus: action.playerStatus,
                player: action.player,
            };
        case 'SET_PODCAST_PLAYER_SETTING':
            return {
                ...state,
                playerSetting: action.setting,
            };
        case 'SET_PODCAST_PLAYER_CONTINUE':
            return {
                ...state,
                continue: action.continue,
            };
        default:
            return state;
    }
};