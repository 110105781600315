import { callApi } from '../service/Api';
import { logout } from './auth';
import { ApiConstants } from '../constants/api';
import { callToast, isApiSuccess } from '../service/Utilities'
import { updateUserPref } from './user';
import { updateUserPref as updateUserPrefSelect, updateUserPrefMultiple, mapPrefKeyValue } from '../selectors/userPreferences';

export const setShareList = ({ list = [] } = {}) => ({
    type: 'SET_SHARE_LIST',
    list
});

export const startSetShareList = () => (dispatch, getState) => new Promise((resolve, reject) => {

        const apiData = {
            url: ApiConstants.SHARE_LIST,
            token: getState().auth.token
        }

        callApi(apiData)
        .then(res => {
            // console.log('startSetShareList', res)
            if(isApiSuccess(res.status)) {
                dispatch(setShareList({
                    list: res.data
                }));
                resolve(res.data);
            }
            else {
                dispatch(logout());
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
        
});

export const setShareNetworks = ({ list = [] } = {}) => ({
    type: 'SET_SHARE_NETWORKS',
    list
});

export const startSetShareNetworks = () => (dispatch, getState) => new Promise((resolve, reject) => {

        const apiData = {
            url: ApiConstants.SHARE_NETWORKS,
            token: getState().auth.token
        }

        callApi(apiData)
        .then(res => {
            // console.log('startSetShareNetworks', res)
            if(isApiSuccess(res.status)) {
                dispatch(setShareNetworks({
                    list: res.data
                }));
                resolve(res.data)
            }
            else {
                dispatch(logout());
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
        
});

export const startUpdateSharePref = ({ socialList = [], socialCountObj={} }) => {
    // console.log('startUpdateSharePref',key,value)
    return (dispatch, getState) => {

        const { key, value } = socialCountObj;

        const apiData = {
            url: ApiConstants.SHARE_PERSONALIZE,
            token: getState().auth.token,
            method: 'POST',
            data: {
                count: value,
                q: socialList.map(({id}) => id).join(",")
            }
        }

        callApi(apiData)
        .then(res => {
            if(isApiSuccess(res.status)) {
                dispatch(setShareList({ list: socialList
                    // .sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
                    .map((item, index) => {
                    return {
                        ...item,
                        order: index+1
                    }
                }) }));
                dispatch(updateUserPref(updateUserPrefSelect({key,value}, getState().user.pref)));
                callToast({type:"success",msg:'Saved Successfully'});
            }
            else {
                if(res.msg) { callToast({type:"error",msg:res.msg}) }
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
        
    };
};



export const startDisconnectShareNetworks = ({network_id, showToast=true}) => (dispatch, getState) => new Promise((resolve, reject) => {

    const apiData = {
        url: ApiConstants.SHARE_NETWORKS_REMOVE,
        token: getState().auth.token,
        method: 'DELETE',
        data: {
            network_id
        }
    }

    callApi(apiData)
    .then(res => {
        // console.log('startDisconnectShareNetworks', res)
        if(isApiSuccess(res.status)) {
            dispatch(startSetShareNetworks());
            resolve(res.data)
            if(showToast)
            {
                callToast({type:"success",msg:'Disconnected Successfully'});
            }
        }
        else {
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
    
});

export const startShareNetworksSort = ({count, network_id , showToast=true}) => (dispatch, getState) => new Promise((resolve, reject) => {

    const apiData = {
        url: ApiConstants.SHARE_NETWORKS_SORT,
        token: getState().auth.token,
        method: 'POST',
        data: {
            count,
            network_id
        }
    }

    callApi(apiData)
    .then(res => {
        // console.log('startDisconnectShareNetworks', res)
        if(isApiSuccess(res.status)) {
            dispatch(startSetShareNetworks());
            resolve(res.data)
            if(showToast)
            {
                callToast({type:"success",msg:'Saved Successfully'});
            }
        }
        else {
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
    
});


export const startShareNetworksBooks = ({network_id}) => (dispatch, getState) => new Promise((resolve, reject) => {

    const apiData = {
        url: ApiConstants.SHARE_NETWORKS_BOOKS,
        token: getState().auth.token,
        data: {
            network_id
        }
    }

    callApi(apiData)
    .then(res => {
        // console.log('startShareNetworksBooks', res)
        if(isApiSuccess(res.status)) {
            // dispatch(startSetShareNetworks());
            resolve(res.data)
        }
        else {
            if(res.msg) { callToast({type:"error",msg:res.msg}) }
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
    
});




