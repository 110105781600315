const authReducerDefaultState = {
    isLoggedIn: false,
    redirect: false,
    cookie: false,
    token: "",
    error: ""
};

export default (state = authReducerDefaultState, action) => {
    // console.log("AUTH",action.type)
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isLoggedIn: action.status,
                token: action.token,
                error: action.error,
                redirect: action.redirect,
            };
        case 'LOGIN_COOKIE':
            return {
                ...state,
                isLoggedIn: action.status,
                token: action.token,
                cookie: action.cookie,
                error: action.error,
                redirect: action.redirect,
            };
        case 'LOGIN_ERROR':
            return {
                ...state,
                isLoggedIn: action.status,
                error: action.error,
            };
        case 'CLEAR_LOGIN_ERROR':
            return {
                ...state,
                error: action.error,
            };
        case 'LOGOUT':
            return {
                ...authReducerDefaultState
            };
        default:
            return state;
    }
};